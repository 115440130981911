import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    linkGrid: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        padding: '30px', 
    },
}));


function Sidebar(props) {
    const { description, social, title } = props;

    return (
        <Grid item xs={12} md={4} id="about">
            <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.200' }}>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Typography>{description}</Typography>
            </Paper>

            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                Social
      </Typography>
            {social.map((network) => (
                <Link
                    display="block"
                    variant="body1"
                    href="#"
                    key={network.name}
                    sx={{ mb: 1 }}
                    className="linkGrid"
                >
                    <Grid container m={1} p={2} >
                        <network.icon />
                        <span>{network.name}</span>
                    </Grid>

                </Link>
            ))}
        </Grid>
    );
}

Sidebar.propTypes = {
    archives: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ).isRequired,
    description: PropTypes.string.isRequired,
    social: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.elementType.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
    title: PropTypes.string.isRequired,
};

export default Sidebar;