import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { Link, animateScroll as scroll } from "react-scroll";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersistentDrawer from './PersistentDrawer'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    // background : '#edede8',
    // backgroundImage: 'url(${""})',
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(2),
    flexShrink: 100,
    width: 120,
    height: 50,
    // justifyContent: 'space-between',
    overflowX: 'auto',
  },
  iconImage: {
    maxWidth: 150,
    flex: 1,
    float: 'none',
    height: 100,
    // marginLeft: 'auto',
    marginRight: 'auto'
  },
}));

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function Header(props) {
  const classes = useStyles();
  const { sections, title } = props;

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar} id="top">

        {isMatch ? <PersistentDrawer sections={sections} /> : (
          <>
            <img src="./atf1.svg" className={classes.iconImage} align="center" />
            <Tabs
              variant="fullWidth"
              // value={value}
              // onChange={handleChange}
              aria-label="nav tabs"
            >
              {sections.map(section => (
                <Link
                  label={section.title}
                  className={classes.toolbarLink}
                  activeClass="active"
                  to={section.url}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}>
                    {section.title}
                </Link>
              ))}
            </Tabs>
          </>
        )}

      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};