import React from 'react'
import { Typography, Card, AppBar, CardActions, CardContent, CardMedia, CssBaseline, Grid, Toolbar, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Header from './Header'
import MainPost from './MainPost'
import Members from './Members'
import Footer from './Footer'
import FeaturedPost from './FeaturedPost'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider';
import Content from './Content';
import Sidebar from './Sidebar';
import Contact from './Contact';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6)
    },
    mainPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing[4],
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
    mainGrid: {
        marginTop: theme.spacing(3),
    },
}));

const sections = [
    { title: '首页', url: 'top', eng: 'Home'},
    { title: '商会简介', url: 'about', eng: 'About Us'},
    { title: '新闻动态', url: 'news', eng: 'News'},
    { title: '主要成员', url: 'members', eng: 'Members'},
    { title: '联系我们', url: 'contact', eng: 'Contact'},
];

const posts = [
    {title: '"《华埠日记》疫境·前行"摄影展在美国旧金山举行', date: '05/09/2021', url: 'http://www.xinhuanet.com/photo/2021-05/09/c_1127424726.htm'}, 
    {title: '停止仇恨亚裔，美国权力部门与社会大众可以做些什么？', date: '03/31/2021', url: 'https://www.shxwcb.com/628203.html'},
    {title: '硅谷独家 | 特朗普能否东山再起？大格局才能成大业！—专访美国潮商基金会理事长林志斯', date: '02/03/2021', url: 'https://www.pacificviewmedia.com/single-post/%E7%A1%85%E8%B0%B7%E7%8B%AC%E5%AE%B6-%E7%89%B9%E6%9C%97%E6%99%AE%E8%83%BD%E5%90%A6%E4%B8%9C%E5%B1%B1%E5%86%8D%E8%B5%B7%EF%BC%9F%E5%A4%A7%E6%A0%BC%E5%B1%80%E6%89%8D%E8%83%BD%E6%88%90%E5%A4%A7%E4%B8%9A%EF%BC%81-%E4%B8%93%E8%AE%BF%E7%BE%8E%E5%9B%BD%E6%BD%AE%E5%95%86%E5%9F%BA%E9%87%91%E4%BC%9A%E7%90%86%E4%BA%8B%E9%95%BF%E6%9E%97%E5%BF%97%E6%96%AF'},
    {title: '美国大选冲刺：重量级挺川人士和重量级拜登的支持者激辩谁该入主白宫', date: '10/29/2020', url: 'https://chineseradioseattle.com/2020/10/29/chinese_debate_who_to_win_the_next_us_president/'}, 
    {title: '王东华总领事出席美国潮商基金会2019年度慈善筹款年会暨祝贺布里德连任旧金山市长晚宴', date: '11/22/2019', url: 'http://www.chinaconsulatesf.org/chn/tpxw/t1717924.htm'},
];

const sidebar = {
    title: '商会简介',
    description:
        '全球海外華人有六千多萬，潮裔約佔一仟多萬。當今世界上美國、中國分別是世界第一、'+
        '第二大經濟體，我們在美的潮人具備天時地利的先天條件。時也勢也！美國潮商會應運而生。商會功能不單單是一個互通互邦的交流平台，更是一個舉辦全美乃至全球性專業研討會的機構。後續將開辦專項投資公司及智庫等，並全力支持在美華裔及對華裔友好人士參政議政，提高我們在美的政治地位，並為促進美中民間友好關係做出貢獻我們的願景是將美國潮商會辦成一個於美中兩國都具有重大影響力的商會。',

    social: [
        { name: 'Twitter', icon: TwitterIcon },
        { name: 'Facebook', icon: FacebookIcon },
    ],
};

const mainPost = {
    title: 'Title of a longer featured blog post',
    description:
        "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
    image: './atf.ai',
    imgText: 'main image description',
    linkText: 'Continue reading…',
};

const featuredPosts = [
    {
        title: '美国潮商基金会为社区捐赠防护用品',
        date: 'Mar 23, 2020',
        url: 'http://www.dingdingtv.com/?p=54935&lang=zh',
        description:
            '美國潮商基金會和美國深圳總商會鑒於近日疫情嚴重為表達支持華裔社區，為此舉辦一個捐贈活動。',
        image: './Post1pic.jpeg',
        imageText: 'Image Text',
        peek: '參加此次活動的人員有：美國潮商基金會理事長 林志斯，美國深圳總商會會長Tammy Li, 潮商基金會榮譽會長林邦立和美亞工商聯合會副會長 陳冠雄。',
    },
    {
        title: '美國潮商基金會 扶弱抗疫 關懷長者',
        date: 'May 17, 2020',
        url: 'https://www.singtaousa.com/sf/13549-%E7%A4%BE%E5%8D%80%E5%83%91%E7%A4%BE/2847544-%E5%90%91%E8%8F%AF%E8%A3%94%E9%95%B7%E8%80%85%E5%8F%8A%E5%A0%85%E5%AE%88%E5%89%8D%E7%B7%9A%E4%BA%BA%E5%93%A1%E9%80%81%E4%B8%8A%E6%9C%89%E6%BA%AB%E5%BA%A6%E7%9A%84%E7%BE%8E%E9%A3%9F%E7%96%AB%E6%83%85%E6%9C%9F%E9%96%93+%E7%BE%8E%E5%9C%8B%E6%BD%AE%E5%95%86%E5%9F%BA%E9%87%91%E6%9C%83%E6%89%B6%E5%BC%B1%E6%8A%97%E7%96%AB+%E9%97%9C%E6%87%B7%E9%95%B7%E8%80%85/',
        description:
            '新型冠狀病毒疫情在全美蔓延的艱難時期，美國潮商基金會密切關注疫情的發展，捐助口罩及醫療防護物資，為抗疫救災提供應急的支援。',
        image: './Post2pic.jpg',
        imageText: 'Image Text',
        peek: '近日，美國潮商基金會愛心行動持續進行中，該會從舊金山兩家餐廳訂購美食熱餐派送給65歲以上且缺乏子女照顧的華人長者。'
    },
];

// const THEME = createMuiTheme({
//     typography: {
//      "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
//      "fontSize": 13,
//      "fontWeightLight": 300,
//      "fontWeightRegular": 400,
//      "fontWeightMedium": 500
//     }
//  });


const App = () => {
    const classes = useStyles();

    return (
        // <ThemeProvider theme={THEME}>
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <Header title="美国潮商基金会" sections={sections} />
                <main >
                    <MainPost post={mainPost} />
                    <Grid container spacing="4">
                        {featuredPosts.map((curPost) =>
                            <FeaturedPost key={curPost.title} post={curPost} />
                        )}
                    </Grid>
                    <Grid container spacing={5} className={classes.mainGrid}>
                        <Content title="新闻动态" posts={posts} />
                        <Sidebar
                            title={sidebar.title}
                            description={sidebar.description}
                            social={sidebar.social}
                        />
                    </Grid>
                    
                </main>
                <Divider />
                <Members />
                <Divider />
                <Contact />
            </Container>

            <Footer title="美国潮商基金会" description="2021" />

        </React.Fragment>
        // </ThemeProvider>
    )
}

export default App