import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    mainPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(2),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainPostContent: {
        position: 'relative',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    }
}))

const post = {
    title: '2021美国潮商基金会迎新春慈善活动举办',
    description:
        "2021年2月13 日(农历大年初二)由美国潮商基金会主办的"+
        "2021年美国潮商基金会迎新春慈善活动在旧金山湾区的圣拉蒙市新硅谷离岸发展中心举办。"+
        "出于对美国疫情的安全防范,本次活动采用线上直播、线下现场出席相结合的方式举办,并通过网络链接来自世界各地数十位政、商、侨界的嘉宾共同庆祝2021辛丑牛年的到来。",
    image: './FeaturedPic.jpeg',
    imgText: 'main image description',
    linkText: 'Continue reading…',
    url: 'http://www.xzsxw.com/2488.html',
};

export default function MainPost(props) {
    const classes = useStyles()
    // const { post } = props

    return (
        <Paper className={classes.mainPost} style={{ backgroundImage: `url(${post.image})` }}>
            {/* Increase the priority of the hero background image */}
            {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}
            <div className={classes.overlay} />
            <Grid container>
                <Grid item md={6}>
                    <div className={classes.mainPostContent}>
                        <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                            {post.title}
                        </Typography>
                        <Typography variant="h5" color="inherit" paragraph>
                            {post.description}
                        </Typography>
                        <Link variant="subtitle1" href={post.url}>
                            {post.linkText}
                        </Link>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}

MainPost.prototypes = {
    post: PropTypes.object,
}