import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    contact: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginLeft: theme.spacing(4),
    },
    paper: {
        height: 80,
        width: 200,
    },
    control: {
        padding: theme.spacing(2),
    },
}))

export default function Contact(props) {
    const classes = useStyles();
    const [spacing, setSpacing] = React.useState(0);

    return (

        <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }}>

            <Grid className={classes.contact} xs={12} >
                <Typography variant="h4" align="center" gutterBottom id="contact">
                    联系我们
                    </Typography>
            </Grid>
            <Grid container direction="row" className={classes.root} spacing={10}>
                <Grid item xs={12}>
                    <Grid container justify="center" spacing={spacing}>
                        <Grid item>
                            <PhoneIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <span>925-355-5312</span>
                        </Grid>

                        <Grid item>
                            <MailIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <span>americanteochewfoundation@gmail.com</span>
                        </Grid>

                        <Grid item>
                            <LanguageIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <span>http://www.americanteochew.com</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
                <Grid className={classes.contact} xs={12} >
                    <Typography variant="h4" align="center" gutterBottom>
                        联系我们
                    </Typography>
                </Grid>
                <Grid container className={classes.root} direction="row" alignItems="center" xs={8} sm={4}>
                    <Grid item xs={4} sm={4} >
                        <Paper className={classes.paper} align="center">
                            925-355-5312
                        </Paper>
                    </Grid>

                    <Grid item>
                        <MailIcon className={classes.icon} />
                    </Grid>
                    <Grid item>
                        <span>info@careusolutions.com</span>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                        <Paper className={classes.paper} align="center">
                            http://www.americanteochew.com
                        </Paper>
                    </Grid>
                </Grid>
            </Grid> */}
        </div>
    )
}