import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles((theme) => ({
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
    contentGrid: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    typo: {
        marginLeft: theme.spacing(2),
    },
    date: {
        marginRight: theme.spacing(2),
    }
}));

export default function Content(props) {
    const classes = useStyles();
    const { posts, title } = props;

    return (
        <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom id={'news'}>
                {title}
            </Typography>
            <Divider />
            {posts.map((post) => (
                
                <Link
                    display="block"
                    variant="h4"
                    href={post.url}
                    key={post}
                    sx={{ mb: 6 }}
                    className=""
                >
                    <Paper className={classes.paper}>
                    <Grid container spacing={2} className={classes.contentGrid}>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography variant="body2" gutterBottom className={classes.typo}>
                                        {post.title}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" color="textSecondary" className={classes.date}>{post.date}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                </Link>
            ))}
        </Grid>
    );
}

Content.propTypes = {
    posts: PropTypes.array,
    title: PropTypes.string,
};