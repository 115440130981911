import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}))

export default function Members(props) {
    const classes = useStyles();

    return (
        <Grid className={classes.root} xs={12} >
            <Typography variant="h4" align="center" gutterBottom id="members">
                商会成员
            </Typography>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <ButtonBase className={classes.image}>
                                <img className={classes.img} alt="complex" src="./Louis.jpg" />
                            </ButtonBase>
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="subtitle1">
                                    林志斯
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                    美国潮商基金会理事长，科优医疗用品有限公司董事长（Care U Products Inc）、智诚科技公司董事长（I City Corp.）。
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* 多年来他一直热心而专注参与美国政治及社区发展，特别是促进美国和中国两者民间友好关系，加强双方经济、社会、文化、教育、医疗和科学活动的合作。在疫情期间，美国潮商基金会多次向旧金山湾区多个城市政府机构、医疗机构、地方警察局等捐赠上万枚口罩、体温计等抗疫工具。2021年，他和Ying Chen、Mr. Gerald Green等出于关爱邻里和服务社区的美好愿望，创立了Care U医疗用品公司，在美国建厂，本地生产的美国本土医疗防护用品，例如医用平面口罩，N95口罩，企业定制口罩等，支持美国发展本国制造业。 */}
                            <Grid item>
                                <Typography variant="subtitle1" color="textSecondary">Louis Lam</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </Grid>
    )
}