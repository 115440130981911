import { CardActionArea } from '@material-ui/core';
import PropTypes from 'prop-types'
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';

const useStyles = makeStyles((theme) => ({
    // root: {
    //     maxWidth: 345,
    // },
    media: {
        width: 600,
        // height: 100,
        display: { xs:'none', sm: 'block' },
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    card: {
        display: 'flex',
        marginTop: theme.spacing(2),
    },
    content: {
        flex: '1 0 auto',
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        width: 160,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        maxWidth: '60',
    },
}));

var params = "menubar=no,toolbar=no,status=no,width=570,height=570";

function shareToFaceBook(url) {
    let shareUrl = `http://www.facebook.com/sharer/sharer.phpu=${url}`;
    window.open(shareUrl, "NewWindow" , params);  
   }

export default function FeaturedPost(props) {
    const { post } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const theme = useTheme();
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleShareClick = () => {
        shareToFaceBook(post.url);
    };

    return (

        <Grid item xs={12} sm={6}>
            <CardActionArea component="a" >
                <Card className={classes.card} href={post.url}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography component="h2" variant="h5">
                                {post.title} 
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {post.date}
                            </Typography>
                            <Typography variant="subtitle1" paragraph>
                                {post.description}
                            </Typography>
                            <Link variant="subtitle1" color="primary" href={post.url}>
                                Continue reading...
                            </Link>
                        </CardContent>
                        <div>
                            <CardActions disableSpacing>
                                <IconButton aria-label="add to favorites">
                                    <FavoriteIcon />
                                </IconButton>
                                <IconButton 
                                    onClick={handleShareClick}
                                    aria-label="share" >
                                    <ShareIcon />
                                </IconButton>
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded,
                                    })}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </CardActions>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Typography paragraph>
                                        {post.peek}
                                    </Typography>
                                </CardContent>
                            </Collapse>
                        </div>
                    </div>
                        <CardMedia
                                className={classes.media}
                                image={post.image}/>
                        
                </Card>
            </CardActionArea>
        </Grid>
    )
}

FeaturedPost.propTypes = {
    post: PropTypes.object,
};